import { LocalizedValue, LocalizedValueJson } from '../../LocalizedValue';
import { RecipeIngredient, RecipeIngredientJson } from './RecipeIngredient';
import { NutritionInformation, NutritionInformationJson } from '../Ingredient/NutritionInformation';
import { RecipeInstruction, RecipeInstructionJson } from './RecipeInstruction';
import { computed, observable, runInAction, toJS } from 'mobx';
import { HttpBackend } from '../../../Services/Http/HttpBackend';
import { v4 as UUID } from 'uuid';
import { RecipeSearchParams, SeasonType } from './RecipeSearchParams';
import { Pageable } from '../../Interfaces/Pageable';
import { Media, MediaJson } from '../../Media/Media';
import { LocalizedEntity, LocalizedEntityJson } from '../../LocalizedEntity';

/**
 * Created by neo on 18.12.20.
 */

export type RecipeJson = LocalizedEntityJson & {
  id: string;
  name: LocalizedValueJson[];
  veryHealthy?: boolean;
  cheap?: boolean;
  veryPopular?: boolean;
  sustainable?: boolean;
  weightWatcherSmartPoints: number;
  aggregateLikes: number;
  healthScore: number;
  creditsText?: string;
  license?: string;
  sourceName?: string;
  pricePerServing: number;
  ingredients: RecipeIngredientJson[];
  readyInMinutes: number;
  servings: number;
  sourceUrl?: string;
  image?: MediaJson;
  nutrition?: NutritionInformationJson;
  summary: LocalizedValueJson[];
  cuisines: string[];
  dishTypes: string[];
  diets: string[];
  occasions: string[];
  seasons: SeasonType[];
  instructions: RecipeInstructionJson[];
  spoonacularSourceUrl?: string;
  favorite: boolean;
};

export class Recipe extends LocalizedEntity {
  @observable
  id = UUID();
  @observable
  veryHealthy?: boolean;
  @observable
  cheap?: boolean;
  @observable
  veryPopular?: boolean;
  @observable
  sustainable?: boolean;
  @observable
  weightWatcherSmartPoints: number = 0;
  @observable
  aggregateLikes: number = 0;
  @observable
  healthScore: number = 0;
  @observable
  creditsText?: string;
  @observable
  license?: string;
  @observable
  sourceName?: string;
  @observable
  pricePerServing: number = 0;
  @observable
  ingredients: RecipeIngredient[] = [];
  @observable
  readyInMinutes: number = 0;
  @observable
  servings: number = 1;
  @observable
  sourceUrl?: string;
  @observable
  image?: Media;
  @observable
  nutrition?: NutritionInformation;
  @observable
  summary: LocalizedValue[] = [];
  @observable
  cuisines: string[] = [];
  @observable
  dishTypes: string[] = [];
  @observable
  diets: string[] = [];
  @observable
  occasions: string[] = [];
  @observable
  seasons: SeasonType[] = [];
  @observable
  instructions: RecipeInstruction[] = [];
  @observable
  spoonacularSourceUrl?: string;
  @observable
  favorite = false;
  readonly type = 'recipe';

  constructor(json?: Partial<RecipeJson>) {
    super(json);
    if (json) {
      this.id = json.id ?? UUID();
      this.veryHealthy = json.veryHealthy;
      this.cheap = json.cheap;
      this.veryPopular = json.veryPopular;
      this.sustainable = json.sustainable;
      this.weightWatcherSmartPoints = json.weightWatcherSmartPoints ?? 0;
      this.aggregateLikes = json.aggregateLikes ?? 0;
      this.healthScore = json.healthScore ?? 0;
      this.creditsText = json.creditsText;
      this.license = json.license;
      this.sourceName = json.sourceName;
      this.pricePerServing = json.pricePerServing ?? 0;
      this.ingredients = (json.ingredients ?? []).map((i) => new RecipeIngredient(i));
      this.readyInMinutes = json.readyInMinutes ?? 0;
      this.servings = json.servings ?? 1;
      this.sourceUrl = json.sourceUrl;
      this.image = json.image ? new Media(json.image) : undefined;
      this.nutrition = new NutritionInformation(json.nutrition);
      this.summary = (json.summary ?? []).map((l) => new LocalizedValue(l));
      this.cuisines = json.cuisines ?? [];
      this.dishTypes = json.dishTypes ?? [];
      this.diets = json.diets ?? [];
      this.occasions = json.occasions ?? [];
      this.seasons = json.seasons ?? [];
      this.instructions = (json.instructions ?? []).map((i) => new RecipeInstruction(i));
      this.spoonacularSourceUrl = json.spoonacularSourceUrl;
      this.favorite = json.favorite ?? false;
    }
  }

  calculate(kcal: number): Recipe {
    return this;
  }

  addFavorite(): Promise<Recipe> {
    return HttpBackend.post(`/coach-program/diet/recipes/favorite`, [this.id]).then(() => {
      runInAction(() => (this.favorite = true));
      return this;
    });
  }

  removeFavorite(): Promise<Recipe> {
    return HttpBackend.delete(`/coach-program/diet/recipes/favorite/${this.id}`).then(() => {
      runInAction(() => (this.favorite = false));
      return this;
    });
  }

  /**
   * This is a dummy function in order to have the same signature as ExploreContent
   * @param lang
   */
  getImage(lang: string): Media | undefined {
    return this.image;
  }

  @computed
  get media(): Media | undefined {
    return this.image;
  }

  @computed
  get durationInMinutes(): number {
    return this.readyInMinutes;
  }

  private static transformRecipeSearchParams(params?: RecipeSearchParams) {
    return Object.assign({}, params, {
      dishTypes: params?.dishTypes?.join(','),
      cuisine: params?.cuisine?.join(','),
      diet: params?.diet?.join(','),
      seasons: params?.seasons?.join(','),
    });
  }

  static search(params?: RecipeSearchParams): Promise<Recipe[]> {
    return HttpBackend.get(`/coach-program/diet/recipes`, this.transformRecipeSearchParams(params)).then((result) =>
      result.map((r) => new Recipe(r)),
    );
  }

  static random(params?: RecipeSearchParams): Promise<Recipe[]> {
    return HttpBackend.get(`/coach-program/diet/recipes/random`, this.transformRecipeSearchParams(params)).then((result) =>
      result.map((r) => new Recipe(r)),
    );
  }

  static get(id: string): Promise<Recipe> {
    return HttpBackend.get(`/coach-program/diet/recipes/${id}`).then((result) => new Recipe(result));
  }

  static findFavorites(params?: Pageable): Promise<Recipe[]> {
    return HttpBackend.get(`/coach-program/diet/recipes/favorite`, toJS(params)).then((result) =>
      (result ?? []).map((res) => new Recipe(res)),
    );
  }
}
