export class Token {
  access_token: string;
  refresh_token: string;
  token_type: string;
  exp = Date.now() / 1000;
  expires_in: number;
  expires_at?: number;
  scope: string;
  jti: string;
  created: number;
  authorities: Array<string> = [];

  constructor(json?: any) {
    if (json) {
      this.access_token = json.access_token;
      this.refresh_token = json.refresh_token;
      this.token_type = json.token_type;
      this.exp = json.exp ?? Date.now() / 1000;
      this.expires_in = json.expires_in;
      this.expires_at = json.expires_at;
      this.scope = json.scope;
      this.jti = json.jti;
      this.created = json.created;
      this.authorities = json.authorities;
    }
  }

  get expired(): boolean {
    if (this.expires_at) {
      return Date.now() >= this.expires_at - 10000;
    } else if (this.exp > 0) {
      return Date.now() > this.exp * 1000;
    }
    return true;
  }
}
