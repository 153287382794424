/**
 * Created by neo on 30.11.16.
 */
import { observable, action, computed, runInAction } from 'mobx';
import { Athlete } from '../Model/Athlete/Athlete';
import { User } from '../Model/User';
import SentryService from '../Services/SentryService';
import AppAuthToken from '../Services/Security/AppAuthToken';
import { Token } from '../Services/Security/Token';

export class AuthenticationStore {
  @observable isAuthenticating = false;
  @observable.ref
  athlete?: Athlete;
  @observable
  authTokenCheckInProgress = true;
  @observable
  authenticated = false;

  @action
  storeAccessToken(accessToken: string): Promise<Athlete> {
    AppAuthToken.authData = new Token({ access_token: accessToken });
    return this.checkAuthData();
  }

  /* actions */
  @action
  async checkAuthData() {
    this.authTokenCheckInProgress = true;
    if (!this.athlete) {
      try {
        const user = await Athlete.me();
        return this.setUserData(user);
      } catch (err) {
        this.authTokenCheckInProgress = false;
        throw err;
      }
    }
    return this.athlete;
  }

  @action
  setAthleteData(athlete: Athlete): Athlete {
    this.athlete = athlete;
    SentryService.setUser(athlete);
    return athlete;
  }

  @action
  setUserData(athlete: Athlete): Athlete {
    this.athlete = athlete;
    this.authenticated = true;
    this.isAuthenticating = false;
    this.authTokenCheckInProgress = false;
    return this.athlete;
  }

  /* computed */
  @computed
  get athleteId(): string | undefined {
    return this.athlete?.id;
  }

  @computed
  get user(): User | undefined {
    return this.athlete?.user;
  }

  @computed
  get userId(): string | undefined {
    return this.user?.id;
  }
}
