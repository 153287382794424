/**
 * Created by katarinababic on 22.11.24.
 */
import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { keyframes, css } from '@emotion/react';
import { BreathingSession } from '../../../Model/Explore/BreathingSession';
import { BreathingExerciseInstructionText } from './BreathingExerciseInstructionText';

const createBreathAnimation = (growTime: number, holdTime: number, shrinkTime: number) => keyframes`
  0% {
    transform: scale(1);
  }
  ${(growTime / (growTime + holdTime + shrinkTime)) * 100}% {
    transform: scale(1.5);
  }
  ${((growTime + holdTime) / (growTime + holdTime + shrinkTime)) * 100}% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
`;

const Container = styled.div<{ backgroundImage?: string }>`
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;

  @media (min-width: 600px) {
    height: 360px;
  }

  @media (min-width: 992px) {
    height: 480px;
  }

  @media (min-width: 1280px) {
    height: 600px;
  }

  /* Pseudo-element for the background image */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: ${({ backgroundImage }) => (backgroundImage ? `url(${backgroundImage})` : 'none')};
    background-size: cover;
    background-position: center;
    filter: blur(10px);
    z-index: 0;
  }

  /* Semi-transparent overlay */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4); /* Adjust color and opacity as needed */
    z-index: 1;
  }
`;

const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; /* Ensure this is above the ::before pseudo-element */
  /* No filter here, so contents are not blurred */
`;

const Circle = styled.div<{
  delay: number;
  isActive: boolean;
  growTime: number;
  holdTime: number;
  shrinkTime: number;
  cycles: number;
}>`
  position: absolute;
  border-radius: 50%;
  opacity: 0.4;
  animation: ${({ isActive, growTime, holdTime, shrinkTime }) =>
    isActive
      ? css`
          ${createBreathAnimation(growTime, holdTime, shrinkTime)}
          ${growTime + holdTime + shrinkTime}s linear
        `
      : 'none'};
  animation-delay: ${({ delay }) => delay}s;
  animation-iteration-count: ${({ cycles }) => cycles};
`;

const TextContainer = styled.div`
  position: absolute;
  bottom: 80px;
  left: 280px;
  right: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  text-align: center;
  z-index: 3;
`;

const createStrokeAnimation = (circumference: number) => keyframes`
  0% {
    stroke-dashoffset: ${circumference};
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const ProgressRing = styled.svg<{
  isActive: boolean;
  growTime: number;
  holdTime: number;
  shrinkTime: number;
  cycles: number;
}>`
  position: absolute;
  width: 350px;
  height: 350px;
  ${({ isActive, growTime, holdTime, shrinkTime, cycles }) =>
    isActive
      ? css`
          animation: ${createBreathAnimation(growTime, holdTime, shrinkTime)} ${growTime + holdTime + shrinkTime}s
            linear;
          animation-iteration-count: ${cycles};
        `
      : ''}
`;

const ProgressCircle = styled.circle<{
  circumference: number;
  animationDuration: number;
  cycles: number;
}>`
  fill: none;
  stroke: #0094c1;
  stroke-width: 8px;
  stroke-dasharray: ${({ circumference }) => circumference};
  stroke-dashoffset: ${({ circumference }) => circumference};
  animation: ${({ circumference }) => createStrokeAnimation(circumference)}
    ${({ animationDuration }) => animationDuration}s linear;
  animation-iteration-count: ${({ cycles }) => cycles};
`;

const InnerCircle = styled(Circle)`
  width: 80px;
  height: 80px;
  background-color: #0094c1;
`;

const MiddleCircle = styled(Circle)`
  width: 120px;
  height: 120px;
  background-color: #0094c1;
`;

const OuterCircle = styled(Circle)`
  width: 160px;
  height: 160px;
  background-color: #0094c1;
`;

const PlayButton = styled.button`
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlayIcon = styled.i`
  border-style: solid;
  border-width: 15px 0 15px 25px;
  border-color: transparent transparent transparent white;
  display: inline-block;
`;

export type BreathingSoundsAndAnimationsProps = {
  activeSession: BreathingSession;
  onStart?: () => void;
  growTime: number;
  holdTime: number;
  shrinkTime: number;
  onEnd?: () => void;
  backgroundImage?: string;
};

export const BreathingSoundsAndAnimations: React.FC<BreathingSoundsAndAnimationsProps> = observer(
  ({ activeSession, onStart, growTime, holdTime, shrinkTime, onEnd, backgroundImage }) => {
    const radius = 100; // Adjusted radius to ensure the circle fits when scaled
    const circumference = 2 * Math.PI * radius;
    const animationDuration = growTime + holdTime + shrinkTime;

    const timersRef = useRef<NodeJS.Timeout[]>([]);

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const inhaleSoundFile = require('../../../Assets/Audio/Breathing/inhale.mp3');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const exhaleSoundFile = require('../../../Assets/Audio/Breathing/exhale.mp3');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const holdSoundFile = require('../../../Assets/Audio/Breathing/hold.mp3');
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const endSoundFile = require('../../../Assets/Audio/Breathing/end.mp3');

    const isActive = activeSession.active;
    const cycles = activeSession.totalCycles;

    useEffect(() => {
      if (isActive) {
        const totalDuration = (growTime + holdTime + shrinkTime) * 1000;
        const cycleCount = cycles || 1;
        const totalCyclesDuration = totalDuration * cycleCount;

        const timers: NodeJS.Timeout[] = [];

        let currentTime = 0;

        for (let i = 0; i < cycleCount; i++) {
          timers.push(
            setTimeout(() => {
              const inhaleSound = new Audio(inhaleSoundFile);
              inhaleSound.play();
            }, currentTime),
          );

          timers.push(
            setTimeout(() => {
              const holdSound = new Audio(holdSoundFile);
              holdSound.play();
            }, currentTime + growTime * 1000),
          );

          timers.push(
            setTimeout(() => {
              const exhaleSound = new Audio(exhaleSoundFile);
              exhaleSound.play();
            }, currentTime + (growTime + holdTime) * 1000),
          );

          currentTime += totalDuration;
        }

        timers.push(
          setTimeout(() => {
            const endSound = new Audio(endSoundFile);
            endSound.play();
            if (onEnd) {
              onEnd();
            }
          }, totalCyclesDuration),
        );

        timersRef.current = timers;

        return () => {
          timersRef.current.forEach(clearTimeout);
          timersRef.current = [];
        };
      }
    }, [isActive, growTime, holdTime, shrinkTime, cycles, onEnd]);

    return (
      <Container backgroundImage={backgroundImage}>
        <InnerContainer>
          {!isActive && (
            <PlayButton onClick={onStart}>
              <PlayIcon />
            </PlayButton>
          )}

          {isActive && (
            <ProgressRing
              isActive={isActive}
              growTime={growTime}
              holdTime={holdTime}
              shrinkTime={shrinkTime}
              cycles={cycles}
              viewBox="0 0 350 350"
            >
              <ProgressCircle
                cx="175"
                cy="175"
                r={radius}
                circumference={circumference}
                animationDuration={animationDuration}
                transform="rotate(-90 175 175)"
                cycles={cycles}
              />
            </ProgressRing>
          )}

          <OuterCircle
            isActive={isActive}
            delay={0}
            growTime={growTime}
            holdTime={holdTime}
            shrinkTime={shrinkTime}
            cycles={cycles}
          />
          <MiddleCircle
            isActive={isActive}
            delay={0.1}
            growTime={growTime}
            holdTime={holdTime}
            shrinkTime={shrinkTime}
            cycles={cycles}
          />
          <InnerCircle
            isActive={isActive}
            delay={0.2}
            growTime={growTime}
            holdTime={holdTime}
            shrinkTime={shrinkTime}
            cycles={cycles}
          />
        </InnerContainer>

        <TextContainer>
          <BreathingExerciseInstructionText activeSession={activeSession} />
        </TextContainer>
      </Container>
    );
  },
);
