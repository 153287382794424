/**
 *
 * Created by andreaskarantzas on 24.03.21.
 */
import { observable } from 'mobx';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { Audited } from '../Audited';

export type NotificationTokenApp = 'club' | 'coach';

export class NotificationToken extends Audited {
  @observable
  app: NotificationTokenApp = 'coach';
  @observable athleteId?: string;

  constructor(json?: any) {
    super(json);
    if (json) {
      this.app = json.app;
      this.athleteId = json.athleteId;
    }
  }

  static registerToken(token: string) {
    return HttpBackend.post('/messaging/notification', { token, app: 'coach-web', type: 'web' }).then(() => this);
  }

  static unregisterToken(token: string) {
    return HttpBackend.delete(`/messaging/notification/${token}`);
  }
}
