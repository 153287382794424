import { Token } from './Token';
import * as ls from 'local-storage';

export class AppAuthTokenClass {
  static STORE_KEY = 'coach_web_user:auth_data';

  authData?: Token;

  async getAuthData(): Promise<Token | undefined> {
    return Promise.resolve(this.authData);
  }

  deleteAuthData() {
    this.authData = undefined;
    return ls.remove(AppAuthTokenClass.STORE_KEY);
  }
}

const AppAuthToken = new AppAuthTokenClass();
export default AppAuthToken;
