/**
 * Created by neo on 28.12.22.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { useTrans } from '../../Store/System/LocalizationStore';
import { AccountDetailsSection } from './AccountDetailsSection';
import { Button } from '../../Components/Button';
import { useRootStore } from '../../Store/useRootStore';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { AccountPointsSection } from './AccountPointsSection';
import { useAuth0 } from '@auth0/auth0-react';

const AccountDetailsCol = styled(Col)`
  margin-bottom: 32px;
`;

const ButtonRow = styled(Row)`
  display: flex;
  align-items: center;

  // include tablets (i.e. iPad Air) and desktop
  @media only screen and (min-width: 820px) {
    justify-content: flex-end;
  }
`;

const DeleteAccountLink = styled.button`
  width: 100%;
  background: none;
  border: none;
  text-decoration: underline;
  margin-top: 24px;

  @media only screen and (min-width: 768px) {
    width: auto;
    margin-top: 0;
  }
`;

const GiveFeedbackButtonCol = styled(Col)`
  button {
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    button {
      width: auto;
    }
  }
`;

const DesktopLogoutButtonCol = styled(Col)`
  button {
    display: none;
  }
  @media only screen and (min-width: 768px) {
    button {
      display: block;
      width: auto;
    }
  }
`;

const MobileLogoutButtonCol = styled(Col)`
  button {
    width: 100%;
    margin-top: 16px;
  }
  @media only screen and (min-width: 768px) {
    button {
      display: none;
    }
  }
`;

export type AccountScreenProps = {};

export const AccountScreen: React.FC<AccountScreenProps> = observer((props) => {
  const { t } = useTrans();
  const { authentication } = useRootStore();
  const { logout } = useAuth0();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(true);

  const navigate = useNavigate();

  const athlete = authentication.athlete;

  useEffect(() => {
    setDeleteDisabled(true);
    const timeout = deleteModalOpen ? setTimeout(() => setDeleteDisabled(false)) : undefined;
    return () => timeout && clearTimeout(timeout);
  }, [deleteModalOpen]);

  const handleLogout = React.useCallback(() => {
    logout({
      logoutParams: {
        returnTo: `https://coach.kinastic.com/auth`,
      },
    });
  }, [logout]);

  const handleShowDeleteAccount = React.useCallback(() => {
    setDeleteModalOpen(true);
  }, []);

  const handleDeleteAccount = React.useCallback(() => {
    if (athlete) {
      athlete.delete().then(() =>
        logout({
          logoutParams: {
            returnTo: `https://coach.kinastic.com/auth`,
          },
        }),
      );
      // analytics.logEvent('Account_Deleted');
    }
    // settings.clear();
    // analytics.logEvent('logout');
  }, [athlete, logout, navigate]);

  const handleToggle = React.useCallback(() => setDeleteModalOpen((p) => !p), []);

  const handleGiveFeedback = React.useCallback(() => navigate('/feedback'), [navigate]);

  console.log('ACCOUNT SCREEN');

  return (
    <React.Fragment>
      <Container>
        <h1>{t('accountScreen.title', { defaultValue: 'ACCOUNT' })}</h1>
        <Row>
          <AccountDetailsCol>
            <AccountDetailsSection />
          </AccountDetailsCol>
        </Row>
        <Row>
          <Col xs={12}>
            <AccountPointsSection />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <GiveFeedbackButtonCol>
              <Button color="secondary" size="sm" onClick={handleGiveFeedback} style={{ marginBottom: 16 }}>
                {t('accountScreen.giveFeedbackButton', { defaultValue: 'Give Feedback' })}
              </Button>
            </GiveFeedbackButtonCol>
          </Col>
          <Col xs={12} md={6}>
            <ButtonRow>
              <Col xs={12} md={3}>
                <MobileLogoutButtonCol>
                  <Button size="sm" onClick={handleLogout}>
                    {t('accountScreen.logoutButton', { defaultValue: 'LOGOUT' })}
                  </Button>
                </MobileLogoutButtonCol>
              </Col>
              <Col xs={12} md={3}>
                <DeleteAccountLink color="link" onClick={handleShowDeleteAccount}>
                  {t('accountScreen.deleteAccountButton', { defaultValue: 'Delete Account' })}
                </DeleteAccountLink>
              </Col>
              <Col xs={12} md={3}>
                <DesktopLogoutButtonCol>
                  <Button size="sm" onClick={handleLogout}>
                    {t('accountScreen.logoutButton', { defaultValue: 'LOGOUT' })}
                  </Button>
                </DesktopLogoutButtonCol>
              </Col>
            </ButtonRow>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={deleteModalOpen} toggle={handleToggle}>
        <ModalHeader toggle={handleToggle}>
          {t('accountScreen.deleteAccountModal.title', { defaultValue: 'Delete Account' })}
        </ModalHeader>
        <ModalBody>
          {t('accountScreen.deleteAccountModal.description', {
            defaultValue:
              'You will loose access to your account and your subscription. We will be unable to restore any data of you.',
          })}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" size="sm" onClick={handleDeleteAccount} disabled={deleteDisabled}>
            {t('accountScreen.deleteAccountModal.deleteButton', { defaultValue: 'Delete Account' })}
          </Button>
          <Button color="link" onClick={handleToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
});
