import { action, computed, observable, reaction, runInAction } from 'mobx';
import { DisposableStore } from './DisposableStore';
import { AuthenticationStore } from './AuthenticationStore';
import { Gym } from '../Model/Gym/Gym';
import { logger } from '../Utils/logger';
import { Customer } from '../Model/Customer';
import { AnalyticsStore } from './Firebase/AnalyticsStore';

export class GymCustomerStore extends DisposableStore {
  @observable.shallow
  currentGym?: Gym;
  @observable
  customer?: Customer;

  constructor(
    readonly authentication: AuthenticationStore,
    readonly analytics: AnalyticsStore,
  ) {
    super();
    this.disposers.push(
      reaction(
        () => this.authentication.athleteId,
        (athleteId) => {
          if (athleteId) {
            Customer.recentCoachCustomer().then((res) => runInAction(() => (this.customer = res)));
          } else {
            runInAction(() => (this.customer = undefined));
          }
        },
      ),
      reaction(
        () => this.authentication.athleteId,
        (athleteId) => {
          if (athleteId) {
            this.fetchCurrentGym();
          } else {
            runInAction(() => (this.currentGym = undefined));
          }
        },
        {
          fireImmediately: true,
          name: 'Fetch gyms',
        },
      ),
      reaction(
        () => this.customer,
        (customer) => {
          if (customer) {
            this.setGymIdUserProperty(customer);
          }
        },
        { name: 'set gymId', fireImmediately: true },
      ),
    );
  }

  setGymIdUserProperty(customer: Customer) {
    if (customer.gymId !== 'flinq') {
      logger('GymCustomerStore:setGymIdUserProperty', customer.gymId);
      this.analytics.setUserProperty('gymId', customer.gymId);
    }
  }

  fetchCurrentGym(): Promise<Gym | undefined> {
    return Gym.currentGym().then((gym) => {
      runInAction(() => (this.currentGym = gym));
      return gym;
    });
  }
}
