/**
 * Created by katarinababic on 5.11.24.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTrans } from '../../Store/System/LocalizationStore';
import { ExploreFilterTag } from '../../Model/Explore/ExploreFilterTag';
import styled from '@emotion/styled';
import { Theme } from '../../Theme/Theme';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagButton = styled.button<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? Theme.Colors.primary : '#f6f6f7')};
  border-radius: 32px;
  border-width: 0;
  margin: 8px 8px 8px 0;
  padding: 8px 16px;
`;

const TagText = styled.span<{ selected?: boolean }>`
  color: ${Theme.Colors.heavyWarmestGrey};
  font-weight: 600;
`;

export type ExploreFilterTagsSectionProps = {
  category?: string;
  query?: string;
  selectedTags?: string[];
  onFilter?: (tags: string[]) => void;
};

export const ExploreFilterTagsSection: React.FC<ExploreFilterTagsSectionProps> = observer(
  ({ category, query, onFilter, selectedTags }) => {
    const [availableTags, setAvailableTags] = useState<ExploreFilterTag[]>([]);

    const { t } = useTrans();

    useEffect(() => {
      ExploreFilterTag.find(
        Object.assign({}, category ? { rootCategories: [category], filterTags: selectedTags } : {}, { query }),
      ).then((result) => setAvailableTags(result));
    }, [category, query, selectedTags]);

    const handleToggleFilter = React.useCallback(
      (tag: string) => {
        if (selectedTags?.includes(tag)) {
          if (selectedTags) {
            onFilter?.(selectedTags.filter((t) => t !== tag));
          }
        } else {
          onFilter?.((selectedTags ?? []).concat([tag]));
        }
      },
      [onFilter, selectedTags],
    );

    return (
      <Container>
        {availableTags.map((tag) => (
          <TagButton key={tag.id} onClick={() => handleToggleFilter(tag.id)} selected={selectedTags?.includes(tag.id)}>
            <TagText>
              {t(`explore.filter_tag.${tag.id}`, {
                defaultValue: tag.id.charAt(0).toUpperCase() + tag.id.slice(1),
              })}
            </TagText>
          </TagButton>
        ))}
      </Container>
    );
  },
);
