/**
 * Created by katarinababic on 6.3.25.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { BreathingSession } from '../../../Model/Explore/BreathingSession';
import { useTrans } from '../../../Store/System/LocalizationStore';
import styled from '@emotion/styled';

const Text = styled.p`
  margin-top: 32px;
  font-size: 18px;
  font-weight: 600;
`;

export type BreathingExerciseInstructionTextProps = {
  activeSession: BreathingSession;
};

export const BreathingExerciseInstructionText: React.FC<BreathingExerciseInstructionTextProps> = observer(
  ({ activeSession }) => {
    const { t } = useTrans();

    return activeSession.completed ? (
      <Text>
        {t('explore.breathing.sessionFinishedMsg', {
          defaultValue:
            'These {{totalBreaths}} deep breaths can help reduce your stress level and improve your focus throughout the day. Come back for more.',
          totalBreaths: activeSession.totalCycles,
        })}
      </Text>
    ) : (
      <Text>
        {activeSession.currentCommand
          ? t(`explore.breathing.commands.${activeSession.currentCommand.type}`)
          : t('explore.breathing.introduction', {
              defaultValue: 'Relax your muscles, stay still and focus on your breath. Press on the circles to start',
            })}
      </Text>
    );
  },
);
