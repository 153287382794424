/**
 * Created by neo on 08.02.17.
 */
import { observable, computed } from 'mobx';
import { MediaSize, MediaSizeJson } from './MediaSize';
import { HttpBackend } from '../../Services/Http/HttpBackend';
import { MediaOverlay, MediaOverlayJson } from './MediaOverlay';
import { MediaAudioTrack, MediaAudioTrackJson } from './MediaAudioTrack';
import { MediaTextTrack, MediaTextTrackJson } from './MediaTextTrack';
import { MediaVideoTrack, MediaVideoTrackJson } from './MediaVideoTrack';
import { MediaInfo } from './MediaInfo';
import { MediaLoop, MediaLoopJson } from './MediaLoop';
import { Audited, AuditedJson } from '../Audited';
import { MediaVisibleRect, MediaVisibleRectJson } from './MediaVisibleRect';
import {MediaVideoAssets, MediaVideoAssetsJson} from "./MediaVideoAssets";

export type MediaJson = AuditedJson & {
  language?: string;
  mediaType: string;
  name?: string;
  description?: string;
  url: string;
  size?: number;
  sizes: Partial<MediaSizeJson>[];
  mediaInfo: MediaInfo;
  overlay: Partial<MediaOverlayJson>[];
  loops: Partial<MediaLoopJson>[];
  audioTracks: Partial<MediaAudioTrackJson>[];
  textTracks: Partial<MediaTextTrackJson>[];
  videoTracks: Partial<MediaVideoTrackJson>[];
  visibleRect?: Partial<MediaVisibleRectJson>;
  apiVideoId?: string;
  videoAssets?: MediaVideoAssetsJson;
  aiContent: boolean;
};

export class Media extends Audited {
  @observable
  language: string | undefined;
  @observable
  mediaType: string = 'unknown';
  @observable
  name: string | undefined;
  @observable
  description: string | undefined;
  @observable
  url: string = '';
  @observable
  size: number | undefined;
  @observable
  sizes: MediaSize[] = [];
  @observable
  mediaInfo: MediaInfo = {};
  @observable
  overlay: MediaOverlay[] = [];
  @observable
  loops: MediaLoop[] = [];
  @observable
  audioTracks: MediaAudioTrack[] = [];
  @observable
  textTracks: MediaTextTrack[] = [];
  @observable
  videoTracks: MediaVideoTrack[] = [];
  @observable
  visibleRect: MediaVisibleRect | undefined;
  @observable
  apiVideoId?: string;
  @observable
  videoAssets?: MediaVideoAssets;
  @observable
  aiContent: boolean = false;

  constructor(json?: Partial<MediaJson>) {
    super(json);
    if (json) {
      this.language = json.language;
      this.mediaType = json.mediaType || 'unknown';
      this.name = json.name;
      this.description = json.description;
      this.url = json.url || '';
      this.size = json.size;
      this.sizes = (json.sizes || []).map((s) => new MediaSize(s));
      this.mediaInfo = json.mediaInfo ?? {};
      this.overlay = (json.overlay ?? [])
        .map((o) => new MediaOverlay(o))
        .sort((a, b) => a.startSeconds - b.startSeconds);
      this.loops = (json.loops ?? []).map((o) => new MediaLoop(o)).sort((a, b) => a.startSeconds - b.startSeconds);
      this.audioTracks = (json.audioTracks ?? []).map((t) => new MediaAudioTrack(t));
      this.textTracks = (json.textTracks ?? []).map((t) => new MediaTextTrack(t));
      this.videoTracks = (json.videoTracks ?? []).map((t) => new MediaVideoTrack(t));
      this.visibleRect = json.visibleRect ? new MediaVisibleRect(json.visibleRect) : undefined;
      this.apiVideoId = json.apiVideoId;
      this.videoAssets = json.videoAssets ? new MediaVideoAssets(json.videoAssets) : undefined;
      this.aiContent = json.aiContent ?? false;
    }
  }

  toJS(newId: boolean = false): MediaJson {
    return Object.assign(super.toJS(newId), {
      language: this.language,
      mediaType: this.mediaType,
      name: this.name,
      description: this.description,
      url: this.url,
      size: this.size,
      sizes: this.sizes.map((s) => s.toJS()),
      mediaInfo: this.mediaInfo,
      overlay: this.overlay.map((o) => o.toJS()),
      loops: this.loops.map((o) => o.toJS()),
      audioTracks: this.audioTracks.map((t) => t.toJS()),
      textTracks: this.textTracks.map((t) => t.toJS()),
      videoTracks: this.videoTracks.map((t) => t.toJS()),
      visibleRect: this.visibleRect?.toJS(),
      aiContent: this.aiContent,
    });
  }

  @computed
  get isImage(): boolean {
    return this.mediaType.startsWith('image');
  }

  @computed
  get isVideo(): boolean {
    return this.mediaType.startsWith('video');
  }

  @computed
  get isMp4(): boolean {
    return this.mediaType === 'video/mp4';
  }

  @computed
  get smallest(): string {
    return (
      this.sizes.find((s) => s.size === 'small')?.url ??
      this.sizes.find((s) => s.size === 'medium')?.url ??
      this.sizes.find((s) => s.size === 'large')?.url ??
      this.url
    );
  }

  @computed
  get smallestOrNothing(): string | undefined {
    return this.sizes.find((s) => s.size === 'small')?.url;
  }

  @computed
  get medium(): string {
    return this.sizes.find((s) => s.size === 'medium')?.url ?? this.smallest;
  }

  @computed
  get largeOrMedium(): string {
    return this.sizes.find((s) => s.size === 'large')?.url ?? this.medium;
  }

  /**
   * useful for video where we do not want to show the original
   */
  @computed
  get largeOrMediumOrSmallest(): string {
    return this.sizes.find((s) => s.size === 'large')?.url ?? this.mediumOrSmallest;
  }

  /**
   * useful for video where we do not want to show the original
   */
  @computed
  get mediumOrSmallest(): string {
    return this.sizes.find((s) => s.size === 'medium')?.url ?? this.smallest;
  }

  /**
   * returns the best matching video uri -> medium, smallest or original if mp4
   */
  @computed
  get videoUri(): string | undefined {
    return this.isVideo
      ? this.sizes.find((s) => s.size === 'medium')?.url ??
          this.sizes.find((s) => s.size === 'small')?.url ??
          this.sizes.find((s) => s.size === 'large')?.url ??
          (this.isMp4 ? this.url : undefined)
      : undefined;
  }

  @computed
  get videoUriObject(): { uri: string } | undefined {
    if (this.videoUri) {
      return { uri: this.videoUri };
    }
    return undefined;
  }

  @computed
  get large(): string {
    return this.sizes.find((s) => s.size === 'large')?.url ?? this.medium ?? this.smallest ?? this.url;
  }

  @computed
  get uriObject(): { uri: string } {
    return { uri: this.url ?? '' };
  }

  @computed
  get smallestPossibleUriObject(): { uri: string } {
    return { uri: this.smallest };
  }

  @computed
  get mediumOrSmallestUriObject(): { uri: string } {
    return { uri: this.mediumOrSmallest };
  }

  @computed
  get largeOrMediumOrSmallestUriObject(): { uri: string } {
    return { uri: this.largeOrMediumOrSmallest };
  }

  static get(mediaId: string): Promise<Media | undefined> {
    return HttpBackend.get(`/media/media/findOne/${mediaId}`).then((media) => (media ? new Media(media) : undefined));
  }

  static list(uid: string): Promise<Media[]> {
    return HttpBackend.get(`/media/${uid}/medias`).then((res) => (res ?? []).map((m) => new Media(m)));
  }
}
