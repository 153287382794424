/**
 * Created by shmbgd on 29.11.22.
 */
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { observer, Provider } from 'mobx-react';
import './Store/Firebase/firebase';
import { Root } from './Root';
import { rootStore } from './Store/useRootStore';
import { PointsCollectedModal } from './Components/PointsCollectedModal';
import { Auth0Provider } from '@auth0/auth0-react';

export type AppProps = {};

export const App: React.FC<AppProps> = observer(() => {
  return (
    <Auth0Provider
      domain="auth.kinastic.com"
      clientId="Tsx3nPnr13gNWvA2JyRXRTwznxIkAqzk"
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
      authorizationParams={{ audience: 'https://api.kinastic.com' }}
    >
      <Provider rootStore={rootStore}>
        <BrowserRouter>
          <PointsCollectedModal />
          <Root />
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  );
});
