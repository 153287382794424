/**
 * Created by neo on 28.08.2024
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import ReactPlayer from 'react-player';
import { VideoLanguageSelector } from './VideoLanguageSelector';
import { Media } from '../../Model/Media/Media';
import { notUndefined } from '../../Utils/notUndefined';
import { useRootStore } from '../../Store/useRootStore';
import { useTrans } from '../../Store/System/LocalizationStore';
import ApiVideoPlayer, { ApiVideoPlayerRef } from '@api.video/react-player';
import { Theme } from '../../Theme/Theme';

const VideoPlayer = styled(ReactPlayer)``;

const AiTranslatedContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const AiMessageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.95);
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 80%;
`;

const CloseAiMessageButton = styled.button`
  margin-top: 10px;
  padding: 10px 20px;
  background-color: white;
  color: black;
  border-radius: 5px;
  border: none;
  cursor: pointer;
`;

const AiMessageWithCountdownContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 20px 60px;
`;

const AiMessageWithCountdown = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const Countdown = styled.p`
  font-size: 18px;
`;

export type KinasticVideoPlayerProps = {
  videos: Media[];
  showLanguageSelector?: boolean;
  onWatchDuration?: (watchedDurationInSeconds: number, totalDurationInSeconds: number) => void;
};

export const KinasticVideoPlayer: React.FC<KinasticVideoPlayerProps> = observer(
  ({ videos, showLanguageSelector = false, onWatchDuration }) => {
    const { language } = useRootStore();
    const { t } = useTrans();

    const [selectedLanguage, setSelectedLanguage] = useState(language.language);
    const [showAIMessage, setShowAIMessage] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [countdown, setCountdown] = useState(6); // Start countdown at 6 seconds
    const [hasCountdownCompleted, setHasCountdownCompleted] = useState(false);
    const [totalDuration, setTotalDuration] = useState(0);
    const [hasVideoEnded, setHasVideoEnded] = useState(false);

    const currentTime = React.useRef(0);
    const videoRef = useRef<ReactPlayer>(null);
    const videoPlayerRef = useRef<ApiVideoPlayerRef>(null);
    const playStartTimeRef = useRef<number | null>(null);
    const totalWatchedTimeRef = useRef<number>(0);

    const videoMedia =
      videos.find((v) => v.language === selectedLanguage) ?? videos.find((v) => v.language === 'en') ?? videos[0];

    const availableLanguages = useMemo(
      () =>
        videos
          .flatMap((s) => s.language)
          .filter((v) => !!v)
          .filter(notUndefined),
      [videos],
    );

    const handleChangeLanguage = useCallback((lang: string) => {
      if (videoRef.current) {
        currentTime.current = videoRef.current.getCurrentTime();
      }
      setSelectedLanguage(lang);
    }, []);

    const handleAIToggle = React.useCallback(() => {
      setIsPlaying((prevPaused) => !prevPaused);
      setShowAIMessage((prevShowAIMessage) => !prevShowAIMessage);
    }, []);

    const handlePlay = useCallback(() => {
      console.log('KinasticVideoPlayer::handlePlay');
      playStartTimeRef.current = Date.now();
      setHasVideoEnded(false);
    }, []);

    const handlePause = useCallback(() => {
      if (playStartTimeRef.current) {
        console.log('KinasticVideoPlayer::handlePause');
        const duration = Date.now() - playStartTimeRef.current;
        totalWatchedTimeRef.current += duration;
        playStartTimeRef.current = null;
      }
    }, []);

    const handleEnded = useCallback(() => {
      if (playStartTimeRef.current) {
        const duration = Date.now() - playStartTimeRef.current;
        totalWatchedTimeRef.current += duration;
        playStartTimeRef.current = null;
      }
      const watchedSeconds = Math.floor(totalWatchedTimeRef.current / 1000);
      onWatchDuration?.(watchedSeconds, totalDuration);
      setHasVideoEnded(true);
    }, [onWatchDuration, totalDuration]);

    const handleTimeUpdate = React.useCallback((currentTimeInSeconds: number) => {
      if (currentTimeInSeconds > 0) {
        // console.log('KinasticVideoPlayer::handleTimeUpdate', currentTimeInSeconds);
        currentTime.current = currentTimeInSeconds;
      }
    }, []);

    useEffect(() => {
      return () => {
        if (playStartTimeRef.current) {
          const duration = Date.now() - playStartTimeRef.current;
          totalWatchedTimeRef.current += duration;
        }

        if (!hasVideoEnded) {
          const watchedSeconds = Math.floor(totalWatchedTimeRef.current / 1000);
          if (watchedSeconds > 0) {
            console.log('KinasticVideoPlayer going to report watched duration', { watchedSeconds });
            onWatchDuration?.(watchedSeconds, totalDuration);
          }
        }
      };
    }, [onWatchDuration, totalDuration, hasVideoEnded]);

    const handleFirstPlay = React.useCallback(() => {
      console.log('KinasticVideoPlayer::handleFirstPlay', currentTime.current);
      videoPlayerRef.current?.setCurrentTime(currentTime.current);
    }, []);

    useEffect(() => {
      setHasVideoEnded(false);
      totalWatchedTimeRef.current = 0;
      playStartTimeRef.current = null;
    }, [videoMedia]);

    useEffect(() => {
      if (countdown > 0 && !hasCountdownCompleted) {
        const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        return () => clearTimeout(timer);
      } else {
        setIsPlaying(true); // Start video after countdown
        setHasCountdownCompleted(true); // Ensure countdown doesn't show again
      }
    }, [countdown, hasCountdownCompleted]);

    useEffect(() => {
      if (videoRef.current) {
        videoRef.current?.seekTo(currentTime.current, 'seconds');
      } else if (videoPlayerRef.current) {
      }
    }, [videoMedia]);

    useEffect(() => {
      console.log('KinasticVideoPlayer::useEffect::isPlaying', isPlaying);
      if (isPlaying) {
        setTimeout(() => videoPlayerRef.current?.play(), 100);
      } else {
        videoPlayerRef.current?.pause();
      }
    }, [isPlaying]);

    return (
      <React.Fragment>
        {videoMedia?.apiVideoId ? (
          <ApiVideoPlayer
            ref={videoPlayerRef}
            video={{ id: videoMedia.apiVideoId }}
            muted={true}
            style={{
              objectFit: 'cover',
              backgroundColor: videoMedia ? 'black' : 'transparent',
              width: '100%',
              height: '100%',
            }}
            theme={{
              linkHover: Theme.Colors.primary,
              linkActive: Theme.Colors.primary,
              trackPlayed: Theme.Colors.primary,
            }}
            hideTitle={true}
            videoStyleObjectFit="cover"
            playbackRate={isPlaying ? 1 : 0}
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
            onFirstPlay={handleFirstPlay}
            onDurationChange={setTotalDuration}
            onTimeUpdate={handleTimeUpdate}
          />
        ) : (
          <VideoPlayer
            ref={videoRef}
            playsinline={true}
            url={videoMedia?.largeOrMediumOrSmallest}
            width="100%"
            height="100%"
            muted={true}
            controls={true}
            playing={isPlaying}
            style={{
              objectFit: 'cover',
              backgroundColor: videoMedia ? 'black' : 'transparent',
            }}
            onPlay={handlePlay}
            onPause={handlePause}
            onEnded={handleEnded}
            onDuration={setTotalDuration}
          />
        )}

        {videoMedia?.aiContent && hasCountdownCompleted && (
          <AiTranslatedContainer onClick={handleAIToggle}>
            {t('video.aiContentToggle', { defaultValue: 'AI Translated' })}
          </AiTranslatedContainer>
        )}
        {showAIMessage && (
          <AiMessageContainer>
            <p>
              {t('video.aiContentMessage', {
                defaultValue:
                  'This video has been AI translated. Some artifacts may appear unnatural. Please be aware that the translation may not be perfect.',
              })}
            </p>
            <CloseAiMessageButton onClick={handleAIToggle}>
              {t('video.closeAiMessage', {
                defaultValue: 'Close',
              })}
            </CloseAiMessageButton>
          </AiMessageContainer>
        )}
        {showLanguageSelector && (
          <VideoLanguageSelector
            availableLanguages={availableLanguages}
            selectedLanguage={selectedLanguage}
            onChangeLanguage={handleChangeLanguage}
          />
        )}
        {videoMedia?.aiContent && !hasCountdownCompleted && (
          <AiMessageWithCountdownContainer>
            <AiMessageWithCountdown>
              {t('video.aiContentTitle', {
                defaultValue:
                  'This video has been AI translated. Some artifacts may appear unnatural. Please be aware that the translation may not be perfect.',
              })}
            </AiMessageWithCountdown>
            <Countdown>
              {t('video.aiContentCountdown', {
                defaultValue: 'Starting in {{countdown}} seconds...',
                countdown,
              })}
            </Countdown>
          </AiMessageWithCountdownContainer>
        )}
      </React.Fragment>
    );
  },
);
