/**
 * Created by neo on 01.01.17.
 */

import { Backend } from './Backend';
import { Token } from '../Security/Token';
import { AuthBackend } from './AuthBackend';

/**
 * Special class that does calls the backend by using the app credentials (client_credentials)
 */
class AppBackendClass {
  authDataPromise?: Promise<Token>;
  authData?: Token;

  authorize(): Promise<Token> {
    if (!this.authDataPromise) {
      if (!this.authData || (this.authData && this.authData.expired)) {
        this.authDataPromise = new AuthBackend()
          .post('/oauth/token')
          .then((data: any) => {
            this.authDataPromise = undefined;
            this.authData = new Token(data);
            return this.authData;
          })
          .catch((err) => {
            this.authDataPromise = undefined;
            throw err;
          });
        return this.authDataPromise!;
      }
      return Promise.resolve(this.authData);
    }
    return this.authDataPromise;
  }

  post(path, data?: Record<string, any>, headers?: Record<string, string>) {
    return this.authorize().then((authData) => {
      return new Backend(authData).post(path, data, { headers });
    });
  }

  put(path, data, headers?: Record<string, string>) {
    return this.authorize().then((authData) => {
      return new Backend(authData).put(path, data, headers);
    });
  }

  delete(path, params?: any, headers?: Record<string, string>) {
    return this.authorize().then((authData) => {
      return new Backend(authData).delete(path, params, { headers });
    });
  }

  get(path, params?: Record<string, any>, headers?: Record<string, string>) {
    return this.authorize().then((authData) => {
      return new Backend(authData).get(path, params, { headers });
    });
  }
}

const AppBackend = new AppBackendClass();
export default AppBackend;
