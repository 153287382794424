/**
 * Created by shmbgd on 29.11.22.
 */
import * as React from 'react';
import { Routes, Route } from 'react-router';
import { LoadingContainer } from './Components/LoadingContainer';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRootStore } from './Store/useRootStore';
import { observer } from 'mobx-react';
import { MainLayout } from './View/Layout/MainLayout';
import { AcademyScreen } from './View/Academy/AcademyScreen';
import { NutritionIndexScreen } from './View/Nutrition/NutritionIndexScreen';
import { MoveIndexScreen } from './View/Move/MoveIndexScreen';
import { MentalHealthIndexScreen } from './View/MentalHealth/MentalHealthIndexScreen';
import { AcademyContentViewScreen } from './View/Academy/View/AcademyContentViewScreen';
import { AccountScreen } from './View/Account/AccountScreen';
import { FeedbackScreen } from './View/Support/Feedback/FeedbackScreen';
import { ContactScreen } from './View/Support/Contact/ContactScreen';
import { PrivacyScreen } from './View/Support/Privacy/PrivacyScreen';
import { TermsScreen } from './View/Support/Terms/TermsScreen';
import { AcademyScheduleScreen } from './View/Academy/Schedule/AcademyScheduleScreen';
import { MoveContentViewScreen } from './View/Move/View/MoveContentViewScreen';
import { AcademyLibraryScreen } from './View/Academy/Library/AcademyLibraryScreen';
import { MoveOndemandLibraryScreen } from './View/Move/Library/MoveOndemandLibraryScreen';
import { MoveScheduleScreen } from './View/Move/Schedule/MoveScheduleScreen';
import { BlogIndexScreen } from './View/Blog/BlogIndexScreen';
import { LoginScreen } from './View/Auth/Login/LoginScreen';
import { CatchAllRoute } from './View/CatchAllRoute';
import { NutritionContentViewScreen } from './View/Nutrition/NutritionContentViewScreen';
import { MentalHealthContentViewScreen } from './View/MentalHealth/MentalHealthContentViewScreen';
import { MentalHealthLibraryScreen } from './View/MentalHealth/MentalHealthLibraryScreen';
import { useAuth0 } from '@auth0/auth0-react';

export type RootProps = {};

export const Root: React.FC<RootProps> = observer(() => {
  const [loading, setLoading] = useState(true);
  const { authentication, coachSubscription } = useRootStore();
  const location = useLocation();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const checkRedirect = React.useCallback(async () => {
    const isAuthPage = location.pathname.startsWith('/auth') || location.pathname.startsWith('/registration');

    return getAccessTokenSilently({
      authorizationParams: {
        audience: 'https://api.kinastic.com',
        scope: 'offline_access openid profile email',
        redirect_uri: window.location.origin,
      },
    })
      .then((token) => authentication.storeAccessToken(token))
      .then(() => coachSubscription.fetchSubscription())
      .then((subscription) => (subscription.plan === 'move' ? '/move' : '/academy'))
      .then((defaultRedirectPath) => {
        if (isAuthPage || location.pathname === '/') {
          navigate(defaultRedirectPath, { replace: true });
        }
      })
      .catch(() => !isAuthPage && navigate(`/auth`, { replace: true }));
  }, [getAccessTokenSilently, location.pathname, coachSubscription, authentication, navigate]);

  useEffect(() => {
    checkRedirect().finally(() => setLoading(false));
  }, [checkRedirect]);

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history;
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (loading) {
    return <LoadingContainer text="Loading Page" />;
  }

  return (
    <Routes>
      <Route path="/*">
        <Route path="auth">
          <Route index element={<LoginScreen />} />
        </Route>
        <Route element={<MainLayout />}>
          {coachSubscription.canShowAcademy && (
            <React.Fragment>
              <Route path="academy">
                <Route index element={<AcademyScreen />} />
                <Route path="schedule" element={<AcademyScheduleScreen />} />
                <Route path="library" element={<AcademyLibraryScreen />} />
                <Route path=":contentId" element={<AcademyContentViewScreen />} />
              </Route>
              <Route path="mental-health">
                <Route index element={<MentalHealthIndexScreen />} />
                <Route path=":contentId" element={<MentalHealthContentViewScreen />} />
                <Route path="library" element={<MentalHealthLibraryScreen />} />
              </Route>
              <Route path="nutrition">
                <Route index element={<NutritionIndexScreen />} />
                <Route path=":contentId" element={<NutritionContentViewScreen />} />
              </Route>
              <Route path="blog" element={<BlogIndexScreen />} />
            </React.Fragment>
          )}
          {coachSubscription.canShowMove && (
            <Route path="move">
              <Route index element={<MoveIndexScreen />} />
              <Route path="schedule" element={<MoveScheduleScreen />} />
              <Route path="library" element={<MoveOndemandLibraryScreen />} />
              <Route path=":contentId" element={<MoveContentViewScreen />} />
            </Route>
          )}

          <Route path="account">
            <Route index element={<AccountScreen />} />
          </Route>
          <Route path="feedback">
            <Route index element={<FeedbackScreen />} />
          </Route>
          <Route path="contact" element={<ContactScreen />} />
          <Route path="privacy-policy" element={<PrivacyScreen />} />
          <Route path="terms" element={<TermsScreen />} />
          <Route path="*" element={<CatchAllRoute />} />
        </Route>
      </Route>
    </Routes>
  );
});
