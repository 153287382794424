/**
 * Created by andreaskarantzas on 15.05.18.
 */
import { action, observable, ObservableMap, reaction, runInAction, toJS } from 'mobx';
import { DisposableStore } from '../../Store/DisposableStore';
import { AthleteConfiguration } from '../../Model/Athlete/AthleteConfiguration';
import { AuthenticationStore } from '../../Store/AuthenticationStore';

/**
 * Used for user specific settings (device independent) -> Global just for users.
 * e.g. language settings
 */
export class RemoteSettingsStore extends DisposableStore {
  @observable loaded: boolean = false;
  @observable
  config?: AthleteConfiguration;

  constructor(readonly authentication: AuthenticationStore) {
    super();

    this.disposers.push(
      reaction(
        () => authentication.athlete,
        (athlete) => {
          if (athlete) {
            this.loadSettings();
          } else {
            this.clear();
          }
        },
        { fireImmediately: true, name: 'Firebase get app preferences' },
      ),
    );
  }

  /**
   * Clears only local data no delete
   */
  @action
  clear() {
    this.config = new AthleteConfiguration();
    this.loaded = false;
  }

  @action
  loadSettings() {
    return AthleteConfiguration.get()
      .then((config) => {
        runInAction(() => (this.config = config));
        return config;
      })
      .then(() => runInAction(() => (this.loaded = true)));
  }

  @action
  remove(key: string) {
    if (this.config) {
      delete this.config.data[key];
    }
    return this.config?.save() ?? Promise.resolve(new AthleteConfiguration());
  }

  @action
  saveSetting(key: string, value: unknown) {
    if (this.config) {
      this.config.data[key] = value;
    }
    return this.config?.save() ?? Promise.resolve(new AthleteConfiguration());
  }

  @action
  setMany(keyValue: Record<string, unknown>) {
    Array.from(Object.entries(keyValue)).forEach(([key, value]) => {
      if (this.config) {
        this.config.data[key] = value;
      }
    });
    return this.config?.save() ?? Promise.resolve(new AthleteConfiguration());
  }

  @action
  set(key: string, value: any) {
    return this.saveSetting(key, value);
  }

  get(key: string, defaultValue?: any): any {
    return this.config?.data[key] ?? defaultValue;
  }

  async delete(): Promise<any> {
    return Promise.resolve();
  }
}
