/**
 * Created by neo on 01.04.23.
 */
import * as React from 'react';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useTrans } from '../../../Store/System/LocalizationStore';
import { AuthLayout } from '../../../Components/Auth/AuthLayout/AuthLayout';
import { ErrorAlert } from '../../../Components/Auth/Alerts/ErrorAlert';
import { Button } from '../../../Components/Button';
import styled from '@emotion/styled';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Form, FormGroup } from 'reactstrap';
import { FormLabel } from '../../../Components/Form/FormLabel';
import { Input } from '../../../Components/Input';
import AppBackend from '../../../Services/Http/AppBackend';
import { EMAIL_REGEX } from '../../../Utils/Constants';

const FormContainer = styled(Container)`
  padding: 0 1rem 0 1rem;
`;

const ButtonContainer = styled.div`
  padding-top: 1rem;
`;

export type LoginScreenProps = {};

export const LoginScreen: React.FC<LoginScreenProps> = observer((props) => {
  const { t } = useTrans();
  const { loginWithRedirect, logout, isLoading, isAuthenticated, user } = useAuth0();

  const [errorCode, setErrorCode] = useState<string | undefined>(undefined);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [email, setEmail] = useState('');
  const [processing, setProcessing] = useState(false);

  const emailValid = email.length > 0 && EMAIL_REGEX.test(email);
  const buttonDisabled = isLoading || processing || !emailValid;
  const secondaryButtonDisabled = isLoading || processing;

  const authenticateButtonText = isAuthenticated
    ? t('newLoginScreen.continueButton', { defaultValue: 'Continue to App' }).toUpperCase()
    : t('newLoginScreen.authenticateButton', { defaultValue: 'Continue' }).toUpperCase();

  const subtitleText = isAuthenticated
    ? t('newLoginScreen.auth.subtitleLoggedIn', {
        defaultValue:
          'You are currently logged in as {{userName}}. Click on Continue to continue to the app or Logout to login with another user.',
        userName: user?.email,
      })
    : t('newLoginScreen.auth.subtitle', {
        defaultValue:
          '**Neu hier?**\n' +
          'Gib deine geschäftliche oder die bei deiner Firma hinterlegte E-Mail-Adresse ein,  \n' +
          'um dich zu registrieren.\n' +
          '\n' +
          '**Willkommen zurück!**\n' +
          'Du hast bereits ein Konto? Melde dich mit deiner Account-E-Mail-Adresse an.',
      });

  const handleChangeEmail = React.useCallback(({ target: { value } }) => {
    setEmail(value);
  }, []);

  const handleShowErrorAlert = React.useCallback((error?: string) => {
    setErrorCode(error);
    setShowErrorAlert(true);
  }, []);

  const handleCloseAlert = React.useCallback(() => {
    setShowErrorAlert(false);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (emailValid) {
      setProcessing(true);
      AppBackend.post('/uaa/authentication/request-strategy', { identifier: email })
        .then((response) =>
          loginWithRedirect({
            authorizationParams: {
              audience: 'https://api.kinastic.com', // the audience is needed to get a proper access_token
              scope: 'offline_access openid profile email', // offline_access is needed for refresh token
              redirect_uri: window.location.origin + '/auth',
              connection: response.connection ?? undefined, // seems stupid but has to be like this (since the value can be null and JS has issues with that)
              ...response.additionalParameters,
            },
          }),
        )
        .finally(() => setProcessing(false));
    }
  };

  const handleLogout = React.useCallback(() => {
    logout({
      logoutParams: {
        returnTo: `https://coach.kinastic.com/auth`,
      },
    });
  }, [logout]);

  // useEffect(() => {
  //   if (!isAuthenticated) {
  //     loginWithRedirect({
  //       authorizationParams: {
  //         audience: 'https://api.kinastic.com', // the audience is needed to get a proper access_token
  //         scope: 'offline_access openid profile email', // offline_access is needed for refresh token
  //         redirect_uri: window.location.origin + '/auth',
  //       },
  //     });
  //   }
  // }, [loginWithRedirect, isAuthenticated]);

  return (
    <AuthLayout
      title={t('newLoginScreen.auth0.title', { defaultValue: 'Welcome to KINASTIC' })}
      subtitle={subtitleText}
      overlayRightTitle={t('newLoginScreen.introTitle', { defaultValue: 'Hello\n' + 'New\n' + 'You' })}
      image={require('../../../Assets/Images/auth_4.png')}
    >
      <Form onSubmit={handleLogin}>
        <FormContainer>
          <FormGroup>
            <FormLabel>{t('newLoginScreen.emailLabel', { defaultValue: 'Your e-mail address' })}</FormLabel>
            <Input
              placeholder={t('newLoginScreen.emailPlaceholder', { defaultValue: 'Your e-mail address' })}
              onChange={handleChangeEmail}
              value={email}
              disabled={processing || isLoading}
              disabledColor={'#3c3c3c'}
            />
          </FormGroup>
        </FormContainer>
        <ButtonContainer>
          <Button block={true} color="success" disabled={buttonDisabled} onClick={handleLogin}>
            {authenticateButtonText}
          </Button>
        </ButtonContainer>
        {isAuthenticated && (
          <ButtonContainer>
            <Button block={true} color="secondary" disabled={secondaryButtonDisabled} onClick={handleLogout}>
              {t('newLoginScreen.logout', { defaultValue: 'Logout' })}
            </Button>
          </ButtonContainer>
        )}
        <ErrorAlert
          toggle={handleCloseAlert}
          isOpen={showErrorAlert}
          title={t(`errors.${errorCode}`, { defaultValue: errorCode })}
          onDismiss={handleCloseAlert}
        />
      </Form>
    </AuthLayout>
  );
});
