import { action, computed, observable, reaction } from 'mobx';
import memoize from 'lodash.memoize';
import i18n from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { useTranslation, initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import { DisposableStore } from '../DisposableStore';
import { DayOfWeek } from '../../Model/DayOfWeek';
import { logger } from '../../Utils/logger';
import { RemoteSettingsStore } from '../../Services/Firebase/RemoteSettingsStore';
import { CURRENCIES, startsOnFriday, startsOnSaturday, startsOnSunday, WEEK } from '../../Utils/CustomLocalization';
import Localization, { TemperatureUnit } from '../../Utils/Localization';
import AppBackend from '../../Services/Http/AppBackend';
import __DEV__ from '../../Utils/Dev';
import AppStore from '../../Services/AppStore';
import { LanguageProvider } from './LanguageProvider';

export function useTrans() {
  const { t, ready, i18n } = useTranslation('coach-web');
  return { t, i18n, ready };
}

const translationGetters: { [key: string]: Function } = {
  // lazy requires (metro bundler does not support symlinks)
  en: () => require('../../Assets/Locales/Languages/en/translations.json'),
  de: () => require('../../Assets/Locales/Languages/de/translations.json'),
  fr: () => require('../../Assets/Locales/Languages/fr/translations.json'),
  it: () => require('../../Assets/Locales/Languages/it/translations.json'),
  // es: () => require('../../Assets/Locales/Languages/es/translations.json'),
};

const dayjsLocales: { [key: string]: () => any } = {
  /* eslint-disable @typescript-eslint/no-var-requires */
  de: () => require('dayjs/locale/de').default,
  'de-at': () => require('dayjs/locale/de-at').default,
  'de-ch': () => require('dayjs/locale/de-ch').default,
  en: () => require('dayjs/locale/en').default,
  'en-ch': () => require('../../Assets/Locales/TimeFormat/en-ch'),
  'en-gb': () => require('dayjs/locale/en-gb').default,
  'en-au': () => require('dayjs/locale/en-au').default,
  'en-ca': () => require('dayjs/locale/en-ca').default,
  'en-ie': () => require('dayjs/locale/en-ie').default,
  'en-il': () => require('dayjs/locale/en-il').default,
  'en-nz': () => require('dayjs/locale/en-nz').default,
  'en-sg': () => require('dayjs/locale/en-sg').default,
  es: () => require('dayjs/locale/es').default,
  'es-do': () => require('dayjs/locale/es-do').default,
  'es-us': () => require('dayjs/locale/es-us').default,
  fi: () => require('dayjs/locale/fi').default,
  fr: () => require('dayjs/locale/fr').default,
  'fr-ca': () => require('dayjs/locale/fr-ca').default,
  'fr-ch': () => require('dayjs/locale/fr-ch').default,
  it: () => require('dayjs/locale/it').default,
  'it-ch': () => require('dayjs/locale/it-ch').default,
  zh: () => require('dayjs/locale/zh').default,
  'zh-cn': () => require('dayjs/locale/zh-cn').default,
  'zh-hk': () => require('dayjs/locale/zh-hk').default,
  'zh-tw': () => require('dayjs/locale/zh-tw').default,
};

const fallback = { languageTag: 'en', isRTL: false };

export const translate = memoize(
  (key: string, config?: any) => i18n.t(key, config),
  (key: string, config?: any) => key + JSON.stringify(config ?? {}),
);

export class LocalizationStore extends DisposableStore {
  @observable
  isRTL: boolean = false;
  @observable
  use24HoursClock: boolean = true;
  @observable
  country: string = 'CH';
  @observable
  private _useMetricSystem: boolean = true;
  @observable
  temperatureUnit: TemperatureUnit = 'celsius';
  @observable
  numberFormat = {
    decimalSeparator: '.',
    groupingSeparator: "'",
  };
  @observable
  timeZone: string = 'Europe/Zurich';
  @observable
  currencies: string[] = ['CHF'];
  @observable
  calendar = 'gregorian';

  constructor(readonly remoteSettings: RemoteSettingsStore) {
    super();
    this.initialize(AppStore.get('language'));
    // this.initialize();

    this.disposers.push(
      reaction(
        () => true,
        (loaded) => {
          if (loaded) {
            logger('setting localization settings');
            this.remoteSettings
              .setMany({
                locales: Localization.getLocales(),
                timezone: Localization.getTimeZone(),
                currencies: Localization.getCurrencies(),
                calendar: Localization.getCalendar(),
                uses24HourClock: Localization.uses24HourClock(),
                usesMetricSystem: Localization.usesMetricSystem(),
                temperatureUnit: Localization.getTemperatureUnit(),
                numberFormat: Localization.getNumberFormatSettings(),
              })
              .catch(() => {
                console.error('failed to save settings');
              });
          }
        },
        { fireImmediately: true, name: 'Save timezone' },
      ),
      //   reaction(
      //     () => this.useMetricSystem,
      //     (metricSystem) => analytics().setUserProperty('unit_system', metricSystem ? 'metric' : 'imperial'),
      //   ),
    );

    Localization.addEventListener('change', this.handleLocalizationChange);
  }

  /* actions */

  @action
  changeLanguage(language: string = 'en') {
    LanguageProvider.language = language;
    AppStore.store('language', language);
    this.changeDayJsLanguage(language);
    return Promise.all([i18n.changeLanguage(language), this.remoteSettings.saveSetting('language', language)]);
  }

  /**
   * The number of minutes returned by getTimezoneOffset() is positive
   * if the local time zone is behind UTC, and negative if the local time zone
   * is ahead of UTC. For example, for UTC+10, -600 will be returned.
   * However: day.js uses in the utcOffset method the opposite values
   */
  @computed
  get timezoneOffset(): number {
    const value = new Date().getTimezoneOffset();
    if (value > 0) {
      return -value;
    } else {
      return Math.abs(value);
    }
  }

  @action
  private initialize(language?: string) {
    // fallback if no available language fits

    logger('LocalizationStore::setI18nConfig', language);

    const { languageTag, isRTL } = Localization.findBestAvailableLanguage(Object.keys(translationGetters)) || fallback;
    const languageCode = language || languageTag || 'en';

    // clear translation cache
    translate.cache.clear && translate.cache.clear();
    // update layout direction
    // I18nManager.forceRTL(isRTL);

    i18n
      .use(initReactI18next)
      .use(ChainedBackend)
      .init({
        debug: __DEV__,
        defaultNS: 'coach-web',
        ns: 'coach-web',
        load: 'languageOnly',
        nsSeparator: '::',
        lng: languageCode,
        fallbackLng: 'en',
        saveMissing: __DEV__,
        interpolation: {
          escapeValue: false,
        },
        backend: {
          backends: [
            HttpBackend,
            resourcesToBackend((lang, ns, callback) => {
              const resources = translationGetters[lang]();
              callback(null, resources);
              // import(`../../Assets/Locales/Languages/${lang}/translations.json`)
              //   .then((res) => callback(null, res))
              //   .catch((e) => callback(e, undefined))
            }),
          ],
          backendOptions: [
            {
              version: 'latest',
              referenceLng: 'en',
              namespace: 'coach-web',
              allowMultiLoading: false,
              loadPath: '/locales/{{lng}}/{{ns}}',
              addPath: '/locales/{{lng}}/{{ns}}',
              queryStringParams: { version: 'latest' },
              request: (options, url, payload, callback) => {
                logger('translation url', url, options, payload);
                if (payload) {
                  AppBackend.post(`/messaging/translation${url}`, payload)
                    .then((res) => {
                      logger('translations', res.translations);
                      callback(null, { status: 200, data: JSON.stringify(res.translations) });
                    })
                    .catch((err) => callback(err));
                } else {
                  AppBackend.get(`/messaging/translation${url}`, options.queryStringParams)
                    .then((res) => {
                      logger('translations', res.translations);
                      callback(null, { status: 200, data: JSON.stringify(res.translations) });
                    })
                    .catch((err) => callback(err));
                }
              },
            },
            {
              // prefix for stored languages
              prefix: 'i18next_res_',
              // expiration
              expirationTime: 24 * 60 * 60 * 1000,
              // language versions
              versions: {},
            },
          ],
        },
        missingKeyHandler: (lngs, ns, key, fallbackValue, updateMissing, options) => {
          logger('missing key', lngs, ns, key, fallbackValue, updateMissing, options);
          AppBackend.post(`/messaging/translation/locales/${lngs}/${ns}`, {
            [key]: fallbackValue ?? options.defaultValue ?? '',
          });

          return options.defaultValue ?? fallbackValue ?? `[${key}]`;
        },
      });

    this.changeDayJsLanguage(languageCode);

    this.updateObservables(languageCode, isRTL);
  }

  private changeDayJsLanguage(languageCode: string) {
    const dayjsLanguage = dayjsLocales[languageCode]
      ? languageCode
      : dayjsLocales[languageCode.toLowerCase()]
      ? languageCode.toLowerCase()
      : 'en';

    const localeLoader = dayjsLocales[dayjsLanguage] || dayjsLocales.en;
    if (localeLoader) {
      localeLoader();
      dayjs.locale(dayjsLanguage);
    }
  }

  @action
  private updateObservables(languageTag: string, isRTL: boolean) {
    LanguageProvider.language = languageTag;
    this.isRTL = isRTL;
    this._useMetricSystem = Localization.usesMetricSystem() ?? true;
    this.use24HoursClock = Localization.uses24HourClock();
    this.temperatureUnit = Localization.getTemperatureUnit();
    this.country = Localization.getCountry();
    this.currencies = Localization.getCurrencies();
    this.numberFormat = Localization.getNumberFormatSettings();
    this.timeZone = Localization.getTimeZone();
    this.calendar = Localization.getCalendar();
  }

  /* computed */

  @computed
  get currency(): string {
    if (this.currencies.length > 0) {
      return this.currencies[0];
    }
    return 'CHF';
  }

  @computed
  get useMetricSystem(): boolean {
    const unitSystem = this.remoteSettings.get('unit_system');
    logger('useMetricSystem', unitSystem);
    if (unitSystem && typeof unitSystem === 'string') {
      return unitSystem !== 'imperial';
    }
    return this._useMetricSystem;
  }

  @computed
  get firstDayOfWeek(): DayOfWeek {
    if (startsOnFriday.includes(this.country)) {
      return 'FRIDAY';
    } else if (startsOnSaturday.includes(this.country)) {
      return 'SATURDAY';
    } else if (startsOnSunday.includes(this.country)) {
      return 'SUNDAY';
    }
    return 'MONDAY';
  }

  @computed
  get week(): DayOfWeek[] {
    const week: DayOfWeek[] = [];
    const startIndex = WEEK.indexOf(this.firstDayOfWeek);
    for (let i = startIndex; i < 7 + startIndex; i += 1) {
      week.push(WEEK[i % WEEK.length]);
    }
    return week;
  }

  @computed
  get language(): string {
    return LanguageProvider.language;
  }

  /* other functions */

  private handleLocalizationChange = () => {
    logger('locale changed');
    this.initialize();
  };
}
